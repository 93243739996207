<template>
  <div class="index-index-container" v-if="activeState">
    <div class="index-image-back">
      <img :src="activeDetail?.bg_img || require('@/assets/littleGameOne/backImage@2x.png')" alt="背景图">
    </div>
    <div class="index-header-box">
      <div class="box-left index-box-size">
        <img class="img" :src="require('@/assets/littleGameOne/bigScreenScan@2x.png')" alt="扫码赢大奖">
        <img class="gif" :src="require('@/assets/littleGameOne/bigScreenFont@2x.gif')" alt="字体gif">
        <!-- <div class="qrcode-index-image" id="qrcode" ref="qrcode"></div> -->
        <img class="qrcode-index-image" :src="activeDetail.mini_page_path" alt="二维码">
      </div>
      <div class="box-center">
        <img :src="activeDetail?.template_img || require('@/assets/littleGameOne/headerTitle@2x.png')" alt="活动标题">
      </div>
      <div class="box-right index-box-size">
        <img :src="require('@/assets/littleGameOne/bigBoxer@2x.png')" alt="虚伪以待">
        <div class="prize-index-box">
          <div class="box-image">
            <img class="icon" :src="require('@/assets/littleGameOne/keenUser@2x.png')" v-if="awardList?.[awardIndex]?.victory_id">
            <span class="boxer">
              <img class="head" :src="awardList?.[awardIndex]?.avatar || require('@/assets/littleGameOne/avatarDefault@2x.png')">
            </span>
          </div>
          <div class="box-messa" v-if="awardList?.[awardIndex]">
            <span class="name">{{awardList?.[awardIndex]?.nickname}}</span>
            <span class="content">{{awardList?.[awardIndex]?.victory_name}}</span>
          </div>
          <div class="box-default" v-else>虚位以待</div>
        </div>
      </div>
    </div>
    <div class="index-content-box">
      <div class="box-left index-box-size" v-if="activeDetail?.left_banner_img">
        <img :src="activeDetail?.left_banner_img" alt="左广告">
      </div>
      <div class="content-index-messa">
        <div class="messa-back">
          <img class="left" :src="require('@/assets/littleGameOne/popLeft@2x.png')" alt="左气泡">
          <img class="right" :src="require('@/assets/littleGameOne/popRight@2x.png')" alt="右气泡">
        </div>
        <div class="messa-list">
          <div class="user-index-box" v-for="(item, index) in currentList" :key="index">
            <div class="user-image">
              <img class="icon" :src="require('@/assets/littleGameOne/keenList@2x.png')" alt="皇冠" v-if="item.is_prize===2">
              <img class="head" :src="item.avatar">
            </div>
            <div class="user-name">{{item.nickname}}</div>
            <div class="user-time">{{item.use_time}}</div>
          </div>
        </div>
      </div>
      <div class="box-right index-box-size" v-if="activeDetail?.right_banner_img">
        <img :src="activeDetail?.right_banner_img" alt="右广告">
      </div>
    </div>
    <div class="index-index-drop" v-show="dropStatus">
      <transition
        enter-active-class="animated bounceIn"
        leave-active-class="animated bounceOut"
        :duration="600"
      >
        <div class="drop-box" v-show="dropStatus">
          <img class="box" :class="screenType" :src="require('@/assets/littleGameOne/keenBackBox@2x.png')">
          <img class="border" :class="screenType" :src="require('@/assets/littleGameOne/keenBackBorder@2x.png')">
          <img class="head" :class="screenType" :src="userDetail.avatar">
          <div class="drop-index-detail" :class="screenType">
            <div class="time" :class="screenType">{{userDetail.use_time}}</div>
            <div class="nickname" :class="screenType">{{userDetail.nickname}}</div>
            <div class="content" :class="screenType">{{userDetail.awardShowName}}</div>
            <div class="image" :class="screenType">
              <img :src="userDetail.awardImg">
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ElMessageBox } from 'element-plus';
// import QRCode from 'qrcodejs2';
import {
  tenActiveDetail,
  tenUserOffset,
  tenTopList
} from '@/api/littleGameOne.js';

export default {
  name: 'tenChallenge',
  data () {
    return {
      screenType: document.body.clientWidth > document.body.clientHeight ? 'horizontal' : 'vertical',
      activeState: false,
      activeDetail: {},
      awardList: [],
      offsetTime: 4000,
      dropTime: 3000,
      swiperTime: 5000,
      userList: [],
      offsetValue: 0,
      currentList: [],
      userDetail: {},
      dropStatus: false,
      awardIndex: 0
    }
  },
  mounted () {
    this.getWinResize()
    this.getActiveDetail()
  },
  methods: {
    getWinResize () {
      const _this = this
      window.onresize = () => {
        if (document.body.clientWidth > document.body.clientHeight && _this.screenType === 'vertical') {
          _this.screenType = 'horizontal'
        } else if (document.body.clientWidth <= document.body.clientHeight && _this.screenType === 'horizontal') {
          _this.screenType = 'vertical'
        }
      }
    },
    getActiveDetail () {
      tenActiveDetail({
        uniq: this.$route.params.id
      }).then(res => {
        switch (parseInt(res.status)) {
          case 200:
            if (new Date().getTime() < res.data.begin_time * 1000) {
              return this.$router.replace({
                path: '/littleGameOne/tenChallengeUnStart',
                query: {
                  id: this.$route.params.id
                }
              })
            }
            else if (new Date().getTime() > res.data.end_time * 1000 || res.data.is_termi == 2) {
              return this.$router.replace({
                path: '/littleGameOne/tenChallengeToEnd',
                query: {
                  id: this.$route.params.id
                }
              })
            } else {
              this.activeState = true
              this.activeDetail = res.data
              // this.creatQrCode(res.data.page_path)
              this.getUserListOffset()
              this.getTenTopList()
              this.getSurplusTime()
            }
            break;
          default:
            ElMessageBox.confirm(
              res.msg,
              '提示',
              {
                confirmButtonText: '我知道了',
                type: 'warning',
                showClose: false,
                showCancelButton: false
              }
            )
            break;
        }
      })
    },
    getSurplusTime () {
      setTimeout(async () => {
        let nowDate = new Date().getTime()
        let result = await tenActiveDetail({
          uniq: this.$route.params.id
        })
        if (nowDate > this.activeDetail.end_time * 1000 || result.data.is_termi == 2) {
          this.$router.replace({
            path: '/littleGameOne/tenChallengeToEnd',
            query: {
              id: this.$route.params.id
            }
          })
        } else {
          this.getSurplusTime()
        }
      }, 1000)
    },
    // creatQrCode(qrcodeUrl) {
    //   this.$refs.qrcode.innerHTML = '';
    //   new QRCode(this.$refs.qrcode, {
    //     text: qrcodeUrl,
    //     render: 'table',
    //     colorDark: '#000000',
    //     colorLight: '#ffffff',
    //     correctLevel: QRCode.CorrectLevel.H,
    //   })
    //   this.$refs.qrcode.childNodes[1].style.width = '7.65vw'
    //   this.$refs.qrcode.childNodes[1].style.height = '7.65vw'
    // },
    getUserListOffset () {
      tenUserOffset({
        uniq: this.$route.params.id,
        pageSize: 10,
        offset: this.offsetValue
      }).then(res => {
        if (res.status === 200) {
          let { userList, offset } = res.data
          if (offset !== this.offset) {
            this.userList = this.userList.concat(userList)
            this.offsetValue = offset
          }
          this.getShowTimeout()
        }
      })
    },
    getShowTimeout () {
      let index = this.currentList?.length || 0
      if (index < this.userList.length) {
        setTimeout(() => {
          let detail = this.userList[index]
          if (detail.is_prize === 2) {
            this.userDetail = detail
            this.dropStatus = true
            setTimeout(() => this.dropStatus = false, this.dropTime)
          }
          this.currentList.unshift(detail)
          this.getShowTimeout()
        }, this.offsetTime)
      } else {
        setTimeout(() => {
          this.getUserListOffset()
        }, this.offsetTime)
      }
    },
    // timeDiff (start_time, end_time) {
    //   return start_time && end_time ? ((end_time - start_time) / 1000).toFixed(3) : '--'
    // },
    getTenTopList () {
      tenTopList({
        uniq: this.$route.params.id,
        topNumber: 10
      }).then(res => {
        if (res.status === 200) {
          this.awardList = res.data?.result || []
          this.awardIndex = 0
          this.getSwiperTimeout()
        }
      })
    },
    getSwiperTimeout () {
      let awardListLen = this.awardList.length
      if (this.awardList.length) {
        setTimeout(() => {
          if (awardListLen > this.awardIndex + 1) {
            this.awardIndex++
            this.getSwiperTimeout()
          } else {
            this.getTenTopList()
          }
        }, this.swiperTime)
      } else {
        setTimeout(() => this.getTenTopList(), this.swiperTime)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.index-index-container{
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .index-image-back{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
    background: linear-gradient(360deg, #B500D3 0%, #6800F1 100%);
    img{
      display: block;
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }
  }
  .index-header-box{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .box-left{
      position: relative;
      margin-left: 1.5vw;
      margin-top: 1.5vw;
      .img{
        width: 100%;
        height: auto;
        object-fit: contain;
      }
      .gif{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 19.8vw;
        height: auto;
        object-fit: contain;
      }
    }
    .box-center{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      img{
        width: 37.7vw;
        height: auto;
      }
    }
    .box-right{
      position: relative;
      margin-right: 1.5vw;
      img{
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
  .index-content-box{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .box-left{
      margin-left: 1.5vw;
      img{
        width: 75.7%;
        max-height: calc(100vh - 15.25vw - 1.5vw);
        object-fit: contain;
      }
    }
    .box-right{
      margin-right: 1.5vw;
      img{
        width: 75.7%;
        max-height: calc(100vh - 15.25vw - 1.5vw);
        object-fit: contain;
      }
    }
  }
  .content-index-messa{
    position: relative;
    flex: 1;
    margin: 1.5vw 1.5vw 0;
    border: 0.6vw solid #c296ff;
    border-radius: 4.5vw;
    box-shadow:rgba(255, 255, 255, 0.8) 0px 0px 2.5vw inset;
    box-sizing: content-box;
    overflow: hidden;
    height: calc(100vh - 20.45vw);
    .messa-back{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left{
        margin: 1.05vw 0 0 2.15vw;
        width: 3.2vw;
        height: auto;
      }
      .right{
        margin: 1.05vw 2vw 0 0;
        width: 3.2vw;
        height: auto;
      }
    }
    .messa-list{
      max-height: calc(100% - 3.3vw);
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 2.4vw 1.1vw 0.9vw;
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 2.2vw);
      &::-webkit-scrollbar{
        width: 0;
      }
    }
  }
}
.qrcode-index-image{
  position: absolute;
  top: 4.2vw;
  left: 1.8vw;
  width: 7.65vw;
  height: 7.65vw;
}
.index-box-size{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 19.8vw;
  height: auto;
}
.user-index-box{
  margin-bottom: 1.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1vw;
  .user-image{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    box-sizing: content-box;
    border: 0.25vw solid #D578FF;
    .icon{
      position: absolute;
      top: -1.1vw;
      left: 0;
      width: 6vw;
      height: auto;
      object-fit: contain;
    }
    .head{
      width: 6vw;
      height: 6vw;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
    }
  }
  .user-name{
    max-width: 6vw;
    font-size: 1vw;
    color: #ffffff;
    margin: 0.5vw 0 0.25vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .user-time{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6vw;
    height: 1vw;
    color: #ffffff;
    font-size: 0.8vw;
    font-weight: Semibold;
    background: rgba(255, 255, 255, 0.23);
    border-radius: 0.8vw;
  }
}
.index-index-drop{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  .drop-box{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .box{
      position: absolute;
      object-fit: contain;
      &.horizontal{
        top: -7.3381vh;
        width: 50.0719vh;
        height: 50.0719vh;
      }
      &.vertical{
        top: -6.1151vw;
        width: 41.7266vw;
        height: 41.7266vw;
      }
    }
    .border{
      object-fit: contain;
      margin: auto;
      z-index: 1;
      &.horizontal{
        width: 35.6115vh;
        height: 60vh;
      }
      &.vertical{
        width: 29.6762vw;
        height: 50vw;
      }
    }
    .head{
      position: absolute;
      display: block;
      object-fit: contain;
      border-radius: 50%;
      &.horizontal{
        top: 6.9064vh;
        left: 6.9064vh;
        width: 21.3669vh;
        height: 21.3669vh;
      }
      &.vertical{
        top: 5.7554vw;
        left: 5.7554vw;
        width: 17.8057vw;
        height: 17.8057vw;
      }
    }
  }
}
.drop-index-detail{
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.horizontal{
    top: 21.3vh;
  }
  &.vertical{
    top: 17.85vw;
  }
  .time{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color: #7E3200;
    font-weight: bolder;
    &.horizontal{
      font-size: 3.2374vh;
      margin-bottom: 7.1223vh;
    }
    &.vertical{
      font-size: 2.6978vw;
      margin-bottom: 5.9352vw;
    }
  }
  .nickname{
    font-weight: Semibold;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    color: #ffffff;
    &.horizontal{
      max-width: 22.5539vh;
      font-size: 2.8057vh;
    }
    &.vertical{
      max-width: 18.7949vw;
      font-size: 2.3381vw;
    }
  }
  .content{
    font-weight: Semibold;
    color: #5B0092;
    text-align: center;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    &.horizontal{
      margin: 1.0791vh 0;
      max-width: 22.5539vh;
      font-size: 1.9424vh;
    }
    &.vertical{
      margin: 0.8992vw 0;
      max-width: 18.7949vw;
      font-size: 1.6187vw;
    }
  }
  .image{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 0.4vw;
    img{
      object-fit: contain;
    }
    &.horizontal{
      width: 7.5539vh;
      height: 7.5539vh;
      img{
        width: 6.4748vh;
        height: 6.4748vh;
      }
    }
    &.vertical{
      width: 6.2948vw;
      height: 6.2948vw;
      img{
        width: 5.3956vw;
        height: 5.3956vw;
      }
    }
  }
}
.prize-index-box{
  position: absolute;
  left: 1.2vw;
  bottom: 1.2vw;
  z-index: 1;
  display: flex;
  align-items: center;
  .box-image{
    position: relative;
    width: 7.35vw;
    height: 7.35vw;
    .icon{
      position: absolute;
      top: -1.15vw;
      left: -0.2vw;
      width: 3.65vw!important;
      height: 2.85vw!important;
      object-fit: contain;
    }
    .boxer{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7.35vw;
      height: 7.35vw;
      background: #D578FF;
      box-shadow: inset 0px 1px 3px 0px rgba(252, 240, 255, 0.5);
      overflow: hidden;
      border-radius: 50%;
    }
    .head{
      width: 6.75vw!important;
      height: 6.75vw!important;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
    }
  }
  .box-messa{
    display: flex;
    flex-direction: column;
    margin-left: 0.7vw;
    height: 7.35vw;
    .name{
      max-width: 9vw;
      white-space:nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
      font-size: 1.5vw;
      font-weight: Semibold;
      color: #3C1A4B;
      margin-bottom: 0.25vw;
      margin-top: 0.4vw;
    }
    .content{
      font-size: 1.15vw;
      color: #8706C0;
      font-weight: Semibold;
      max-width: 9vw;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .box-default{
    height: 7.35vw;
    line-height: 7.35vw;
    font-size: 1.5vw;
    color: #8339A4;
    font-weight: Semibold;
    margin-left: 0.7vw;
  }
}
</style>